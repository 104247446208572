import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import FormGroup from "@material-ui/core/FormGroup";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import InputBase from "@material-ui/core/InputBase";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import SearchIcon from "@material-ui/icons/Search";
import FormHelperText from "@material-ui/core/FormHelperText";

import Tooltip from "../tooltip/TooltipComponent";
import CustomizedSnackbar from "../snackBar/snackBarComponent";

const useStyles = makeStyles((theme) => ({
  formGroup: {
    width: "80%",
  },
  items: {
    display: "flex",
    flexWrap: "wrap",
  },
  menuItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    padding: theme.spacing(2),
    "&:hover": {
      backgroundColor: theme.palette.container.light,
    },
  },
  menuItemContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  container: {
    marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  menuItemGroup: {
    borderRadius: 4,
    fontSize: 12,
    padding: 2,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: "100%",
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  icon: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  rootSelect: {
    minWidth: 220,
  },
}));

const RenderSelectedItems = ({ renderItem, list, id, attribute, selected }) => {
  const classes = useStyles();
  return (
    <div className={classes.items}>
      {selected.map((value) => {
        if (value !== undefined && value !== "search") {
          return renderItem(list, value, id, attribute, value);
        }
        return true;
      })}
    </div>
  );
};

const RenderActions = ({ name, data, onChange }) => {
  const classes = useStyles();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [message, setMessage] = useState("");

  const actionClick = (e, action, message) => {
    setMessage(message);
    setOpenSnackBar(true);
    if (action === "copy") {
      localStorage.setItem(`${action}-${name}`, data);
    }
    if (action === "paste" && localStorage.getItem(`copy-${name}`)) {
      onChange(
        {
          target: {
            value: localStorage.getItem(`copy-${name}`).split(","),
          },
        },
        name
      );
    }
  };

  return (
    <div>
      <Tooltip title={<p>Copy</p>}>
        <IconButton
          onClick={(e) => actionClick(e, "copy", "copied!")}
          color="inherit"
          aria-label="Open drawer"
        >
          <Icon className={classNames(classes.icon, "fas fa-copy")} />
        </IconButton>
      </Tooltip>
      <Tooltip title={<p>Paste</p>}>
        <IconButton
          onClick={(e) => actionClick(e, "paste", "paste!")}
          color="inherit"
          aria-label="Open drawer"
        >
          <Icon className={classNames(classes.icon, "fas fa-paste")} />
        </IconButton>
      </Tooltip>
      <CustomizedSnackbar
        variant="success"
        onClose={() => setOpenSnackBar(false)}
        message={message}
        open={openSnackBar}
      />
    </div>
  );
};

const SearchSelectMulti = ({
  name,
  label,
  list,
  id,
  attribute,
  data,
  onChange,
  renderItem,
  enableCopyPaste,
  mandatory,
  error,
  postFixComponent,
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState("");

  return (
    <FormGroup className={classes.formGroup}>
      <InputLabel htmlFor={id}>
        {label}
        {mandatory ? " *" : ""}
      </InputLabel>
      <div className={classes.container}>
        <div>
          <Select
            multiple
            value={data}
            onChange={(e) => {
              return onChange(e, name);
            }}
            onClose={(e) => {
              return setSearch("");
            }}
            classes={{
              root: classes.rootSelect,
            }}
            input={<Input id={name} />}
            renderValue={(selected) => {
              return (
                <RenderSelectedItems
                  renderItem={renderItem}
                  list={list}
                  id={id}
                  attribute={attribute}
                  selected={selected}
                />
              );
            }}
          >
            <div className={classes.search} value={"search"}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => {
                  return setSearch(e.target.value);
                }}
              />
            </div>
            {list.length
              ? list
                  .filter((item) => {
                    if (search && search.length >= 2) {
                      if (
                        item["group"].includes(search) ||
                        item[id].includes(search)
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                    return true;
                  })
                  .map((i) => {
                    return (
                      <div
                        key={i[id]}
                        className={classes.menuItem}
                        value={i[id]}
                      >
                        <div className={classes.menuItemContent}>
                          <Checkbox checked={data.indexOf(i[id]) > -1} />
                          <Typography>
                            {attribute ? i[attribute] : i[id]}
                          </Typography>
                        </div>
                        {i["group"] ? (
                          <div
                            className={classes.menuItemGroup}
                            style={{ backgroundColor: i["colorGroup"] }}
                          >
                            {i["group"]}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })
              : null}
          </Select>
          {error ? <FormHelperText>{error}</FormHelperText> : ""}
        </div>
        {enableCopyPaste ? (
          <RenderActions name={name} data={data} onChange={onChange} />
        ) : null}
        {postFixComponent ? postFixComponent : null}
      </div>
    </FormGroup>
  );
};

SearchSelectMulti.propTypes = {};

export default SearchSelectMulti;
