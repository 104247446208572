import React, { Component } from "react";
import PropTypes from "prop-types";

import ClientsForm from "./clientsForm";
import FeatureFlags from "./featureFlags/featureFlags";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Redirect } from "react-router-dom";
import { validateEmail } from "../../../utils/utils";

import TableComponent from "../../commons/table/tableComponent";
import DialogContent from "../../commons/dialog/contentDialogComponent";
import SimpleMenu from "../../commons/simpleMenu/simpleMenuComponent";
import SelectComponent from "../../commons/select/selectComponent";
import Icon from "@material-ui/core/Icon";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 7,
  },
  buttonAddClient: {
    marginLeft: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  editAction: {
    cursor: "pointer",
    marginRight: theme.spacing(2),
  },
  filter: {
    marginLeft: theme.spacing(2),
  },
});

class ClientsConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientSelected: 0,
      redirectClientPage: null,
      hasDefaultValue: 0,
      openDialogClients: false,
      sales_filter: "no",
      mode: "add",
      defaultValue: {},
      error: {},
      editable: props.editable,
      creatable: props.creatable,
      tagList: props.tagList || [],
      industryList: props.industryList || [],
      planList: props.planList || [],
      modalContent: null,
      columns: [
        {
          field: "actions",
          Title: "Actions",
          render: (rowData) => this.onCreateActions(rowData),
        },
        {
          title: "Logo",
          cellStyle: {
            padding: 2,
            paddingLeft: 8,
          },
          field: "logo",
          render: (rowData) => (
            <img
              src={rowData.logo}
              alt={"logo"}
              style={{ width: 40, borderRadius: "50%" }}
            />
          ),
        },
        {
          title: "Name",
          cellStyle: {
            padding: 2,
            paddingLeft: 14,
          },
          field: "name",
        },
        {
          title: "Short Name",
          cellStyle: {
            padding: 2,
            paddingLeft: 14,
          },
          field: "clientShortName",
        },
        {
          title: "Abbreviation",
          cellStyle: {
            padding: 2,
            paddingLeft: 14,
          },
          field: "abbreviation",
        },
        {
          title: "Industry",
          cellStyle: {
            padding: 2,
            paddingLeft: 14,
          },
          field: "industry",
        },
        {
          title: "Tags",
          cellStyle: {
            padding: 2,
            paddingLeft: 14,
          },
          field: "tags",
          render: (rowData) => this.displayNumber(rowData.tags),
        },
        {
          title: "Emails Alert",
          cellStyle: {
            padding: 2,
            paddingLeft: 14,
          },
          field: "emailsAlert",
          render: (rowData) => this.displayEmails(rowData.emailsAlert),
        },
      ],
      data: props.clientList.filter(
        (client) => client.salesAnalysisClient === false
      ),
      client: {},
    };

    this.clickItemFromComment.bind(this);
  }

  onCreateActions(rowData) {
    const {
      classes,
      taskHistoryViewable,
      channelWhitelistViewable,
    } = this.props;
    const { editable } = this.state;

    const items = [
      {
        action: "tags-example",
        href: null,
        label: "Tags Example",
        data: {
          position: 0,
        },
      },
      {
        action: "actions",
        href: null,
        label: "Actions",
        data: {
          position: 1,
        },
      },
      {
        action: "assets",
        href: null,
        label: "Assets",
        data: {
          position: 2,
        },
      },
      {
        action: "groups",
        href: null,
        label: "Groups",
        data: {
          position: 3,
        },
      },
      {
        action: "reply",
        href: null,
        label: "Reply",
        data: {
          position: 4,
        },
      },
      {
        action: "clara",
        href: null,
        label: "Clara",
        data: {
          position: 5,
        },
      },
      {
        action: "feature-flags",
        href: null,
        label: "Feature Flags",
        modalComponent: <FeatureFlags />,
      },
      {
        action: "sf",
        href: null,
        label: "AB Sticky Filters",
        data: {
          position: 6,
        },
      },
      {
        action: "gg",
        href: null,
        label: "AB Guideline Groups",
        data: {
          position: 7,
        },
      },
      {
        action: "mg",
        href: null,
        label: "AB Moderation Guidelines",
        data: {
          position: 8,
        },
      },
      {
        action: "ab-reports",
        href: null,
        label: "AB Reports",
        data: {
          position: 9,
        },
      },
    ];

    if (taskHistoryViewable && rowData.copyrightClient) {
      items.push({
        action: "task-history",
        href: null,
        label: "Task History",
        data: {
          position: 10,
        },
      });
    }

    if (channelWhitelistViewable && rowData.copyrightClient) {
      items.push({
        action: "channel-whitelist",
        href: null,
        label: "Channel Whitelist",
        data: {
          position: 11,
        },
      });
    }

    return editable ? (
      <div className={classes.actionsContainer}>
        <div className={classes.editAction}>
          <Icon
            className={classes.editIcon}
            onClick={(e) => this.actionEditClient(e, rowData)}
          >
            edit
          </Icon>
        </div>
        <div className={classes.otherActions}>
          <SimpleMenu
            clickItemFromComment={(type, data, modalComponent, callback) =>
              this.clickItemFromComment(
                type,
                data,
                modalComponent,
                callback,
                rowData
              )
            }
            items={items}
          />
        </div>
      </div>
    ) : (
      ""
    );
  }

  clickItemFromComment(_, data, modalComponent, callback, rowData) {
    if (modalComponent) {
      this.setState({
        modalContent: React.cloneElement(modalComponent, {
          rowData,
          onClose: this.closeDialogClients.bind(this),
        }),
        openDialogClients: true,
      });
    } else {
      this.redirectClientPage(
        rowData.tableData.id,
        rowData.clientShortName,
        data.position
      );
      callback();
    }
  }

  redirectClientPage(clientSelected, clientShortName, value) {
    this.setState({
      clientSelected: clientSelected,
      redirectClientPage: clientShortName,
      hasDefaultValue: value,
    });
  }

  displayEmails(emails) {
    let value = ["none"];
    if (typeof emails === "string") {
      value = emails.split(",");
    } else {
      if (emails.length) {
        value = emails;
      }
    }

    return (
      <p>
        {value && value.length > 1
          ? `${value[0]} +${value.length - 1}`
          : value[0]}
      </p>
    );
  }

  displayNumber(items) {
    return <div>{items.length}</div>;
  }

  onChangeClientForm(data) {
    this.setState({ client: data });
  }

  emailValidation(email) {
    return validateEmail(email);
  }

  checkForErrors() {
    const { client } = this.state;

    const promise = new Promise((resolve, reject) => {
      this.setState(
        {
          error: {
            name: client.name ? "" : "Name is mandatory",
            clientShortName: client.clientShortName
              ? ""
              : "Short name is mandatory",
            industry: client.industry ? "" : "Industry is mandatory",
            adbastion_plan: client.adbastion_plan ? "" : "Plan is mandatory",
            tags:
              client.tags && client.tags.length
                ? ""
                : "At least one tag is mandatory",
            emailsAlert: !client.emailsAlert
              ? "Email alert is mandatory"
              : client.emailsAlert.filter(
                  (email) => this.emailValidation(email) === false
                ).length > 0
              ? "Email(s) not valid"
              : "",
            initTime: client.initTime ? "" : "Init Time is mandatory",
            sla: client.sla ? "" : "SLA is mandatory",
            alertSla: client.alertSla ? "" : "Alert SLA is mandatory",
            care_tags:
              client.care_enabled &&
              (!client.care_tags || !client.care_tags.length)
                ? "Care tags are mandatory"
                : "",
            adbastion_tags:
              client.adbastion_moderation_enabled &&
              (!client.adbastion_tags || !client.adbastion_tags.length)
                ? "Safety tags are mandatory"
                : "",
          },
        },
        () => {
          resolve();
        }
      );
    });

    return promise;
  }

  updateClient() {
    this.checkForErrors().then(() => {
      if (
        !Object.values(this.state.error).filter((value) => value !== "").length
      ) {
        if (this.state.mode === "add") {
          this.props.addClient(this.state.client);
        } else {
          this.props.editClient(this.state.clientSelected, this.state.client);
        }
        this.closeDialogClients();
      }
    });
  }

  openDialogClients() {
    this.setState({
      openDialogClients: true,
    });
  }

  closeDialogClients(e = null, reason = "") {
    if (reason !== "backdropClick") {
      this.setState({
        openDialogClients: false,
      });
    }
  }

  actionAddClient(e) {
    const defaultValue = {
      name: "",
      clientShortName: "",
      logo: "",
      abbreviation: "",
      emailsAlert: "",
      tags: [],
      adbastion_tags: [],
      care_tags: [],
      care_review_tags: [],
      adbastion_auto_crawling: false,
      adbastion_moderation_enabled: false,
      care_agent_stats_enabled: false,
      adbastion_publishing_enabled: false,
      care_enabled: false,
      adbastion_moderation_alerts_enabled: false,
      trial_subscription_init: false,
      trial_subscription_init_time: "",
      managed: false,
      industry: "",
      adbastion_plan: "ESSENTIAL",
      otherIndustry: "",
      initTime: "",
      sla: 900,
      alertSla: 1800,
      queryOnlyActive: true,
      allowPublishedPosts: true,
      pullInsights: false,
      useNewIds: true,
      privateRepliesUserTimespan: 14,
    };

    this.setState(
      {
        mode: "add",
        defaultValue: defaultValue,
        client: defaultValue,
      },
      () => {
        this.openDialogClients();
      }
    );
  }

  actionEditClient(e, data) {
    const { industryList } = this.state;
    const defaultValue = {
      name: data.name,
      clientShortName: data.clientShortName,
      abbreviation: data.abbreviation,
      logo: data.logo,
      emailsAlert: data.emailsAlert,
      tags: data.tags,
      adbastion_tags: data.adbastion_tags,
      care_tags: data.care_tags,
      care_review_tags: data.care_review_tags,
      adbastion_auto_crawling: data.adbastion_auto_crawling,
      adbastion_moderation_enabled: data.adbastion_moderation_enabled,
      care_enabled: data.care_enabled,
      care_agent_stats_enabled: data.care_agent_stats_enabled,
      adbastion_publishing_enabled: data.adbastion_publishing_enabled,
      adbastion_moderation_alerts_enabled:
        data.adbastion_moderation_alerts_enabled,
      adbastion_moderation_init_time: data.adbastion_moderation_init_time,
      adbastion_insights_init_time: data.adbastion_insights_init_time,
      care_init_time: data.care_init_time,
      adbastion_moderation_guideline_update_time:
        data.adbastion_moderation_guideline_update_time,
      trial_subscription_init: data.trial_subscription_init,
      trial_subscription_init_time: data.trial_subscription_init_time,
      managed: data.managed,
      industry: data.industry
        ? industryList.map((industry) => industry.name).includes(data.industry)
          ? data.industry
          : "Other"
        : "",
      otherIndustry: data.industry
        ? !industryList.includes(data.industry)
          ? data.industry
          : ""
        : "",
      adbastion_plan: data.adbastion_plan ? data.adbastion_plan : "ESSENTIAL",
      initTime: data.initTime ? data.initTime : "",
      sla: data.sla ? data.sla : 900,
      alertSla: data.alertSla ? data.alertSla : 1800,
      queryOnlyActive: data.queryOnlyActive,
      allowPublishedPosts: data.allowPublishedPosts,
      pullInsights: data.pullInsights,
      privateRepliesUserTimespan: data.privateRepliesUserTimespan || 0,
    };

    this.setState(
      {
        mode: "edit",
        clientSelected: data.key,
        defaultValue: defaultValue,
        client: defaultValue,
      },
      () => {
        this.openDialogClients();
      }
    );
  }

  updateClientList() {
    let value = this.state.sales_filter === "no" ? false : true;

    this.setState({
      data:
        this.state.sales_filter === "all" && this.props.clientList.length
          ? this.props.clientList
          : this.props.clientList.filter(
              (client) => client.salesAnalysisClient === value
            ),
    });
  }

  handleChange(e, id) {
    this.setState(
      {
        [id]: e.target.value,
      },
      () => {
        this.updateClientList();
      }
    );
  }

  displayButtonsClient() {
    const { classes } = this.props;
    const { mode } = this.state;
    return (
      <div className={classes.buttonContainer}>
        <Button
          size="small"
          color="primary"
          onClick={(e) => {
            return this.updateClient();
          }}
        >
          {mode === "add" ? "Add client" : "Edit client"}
        </Button>
        <Button
          onClick={(e) => {
            return this.closeDialogClients(e);
          }}
          size="small"
          color="secondary"
        >
          Cancel
        </Button>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tagList !== this.props.tagList) {
      this.setState({
        tagList: this.props.tagList,
      });
    }

    if (prevProps.industryList !== this.props.industryList) {
      this.setState({
        industryList: this.props.industryList,
      });
    }

    if (prevProps.planList !== this.props.planList) {
      this.setState({
        planList: this.props.planList,
      });
    }

    if (prevProps.clientList !== this.props.clientList) {
      this.updateClientList();
    }

    if (prevProps.creatable !== this.props.creatable) {
      this.setState({
        creatable: this.props.creatable,
      });
    }

    if (prevProps.editable !== this.props.editable) {
      this.setState({
        editable: this.props.editable,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const {
      columns,
      data,
      actions,
      openDialogClients,
      defaultValue,
      creatable,
      mode,
      error,
      tagList,
      sales_filter,
      industryList,
      planList,
      redirectClientPage,
      hasDefaultValue,
      clientSelected,
      modalContent,
    } = this.state;

    if (redirectClientPage) {
      return (
        <Redirect
          to={{
            pathname: `/admin/${redirectClientPage}`,
            state: {
              clientShortName: redirectClientPage,
              hasDefaultValue: hasDefaultValue,
              clientTags:
                data[clientSelected] && data[clientSelected]["tags"]
                  ? data[clientSelected]["tags"]
                  : [],
            },
          }}
        />
      );
    }

    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h5">Client list</Typography>
          {creatable ? (
            <Button
              variant="contained"
              className={classes.buttonAddClient}
              size="small"
              color="primary"
              onClick={(e) => {
                return this.actionAddClient(e);
              }}
            >
              Add client
            </Button>
          ) : (
            ""
          )}
          <span className={classes.filter}>
            <SelectComponent
              id={"sales_filter"}
              label={"Sales Filter"}
              defaultValue={"no"}
              value={sales_filter}
              items={["all", "yes", "no"]}
              onChange={this.handleChange.bind(this)}
            />
          </span>
        </div>
        <TableComponent
          editable={false}
          columns={columns}
          data={data}
          actions={actions}
          title=""
        />
        <DialogContent
          title={"Client"}
          open={openDialogClients}
          close={(e, reason) => {
            this.closeDialogClients(e, reason);
          }}
          fullWidth={true}
        >
          {modalContent ? (
            modalContent
          ) : (
            <div>
              <ClientsForm
                defaultValue={defaultValue}
                tagList={tagList}
                industryList={industryList}
                planList={planList}
                onChangeClientForm={this.onChangeClientForm.bind(this)}
                error={error}
                mode={mode}
              />
              {this.displayButtonsClient()}
            </div>
          )}
        </DialogContent>
      </div>
    );
  }
}

ClientsConfiguration.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  clientList: PropTypes.array,
  editClient: PropTypes.func,
  creatable: PropTypes.bool,
  editable: PropTypes.bool,
  addClient: PropTypes.func,
  industryList: PropTypes.array,
  planList: PropTypes.array,
  tagList: PropTypes.array,
};

export default withStyles(styles, { withTheme: true })(ClientsConfiguration);
